import React from "react";
import Form from "components/invitations/attendeecard/Form";
import VehicleForm from "components/invitations/vehiclecard/Form";
import { Modal } from "@mui/material";
import { useModal } from "contexts/modalContext";
import { ModalItemsContainer } from "components/CustomModal/Styles";

function AttendeeBadgeModal({ event, invitations = null, formType = null }) {
  const { showModal, toggleModal, modalPayload } = useModal();
  const { rowValue } = modalPayload;

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
        position: "absolute",
        top: "0",
      }}
      open={showModal.modal5}
      onClose={() => toggleModal("modal5")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalItemsContainer width="70rem">
        {formType === "vehicleForm" ? (
          <VehicleForm invitations={rowValue} event={event} />
        ) : (
          <Form invitations={rowValue} event={event} />
        )}
      </ModalItemsContainer>
    </Modal>
  );
}

export default AttendeeBadgeModal;
