import { alpha, styled } from "@mui/system";
import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";
import { useModal } from "../../contexts/modalContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
// import { CustomInput } from "../form-components/InputField";
// import StyledResponsiveBox from "../form-components/StyledResponsiveBox";

import { useState } from "react";
import {
  FormikPasswordField,
  // FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  fontWeight: "500",
  transition: "all .2s",
  width: "50%",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
  "& .MuiLoadingButton-loadingIndicator": {
    color: "white",
  },
}));

export const StyledModalItemsContainer = styled(Paper)(({ theme }) => ({
  position: "relative",
  background: "#fff",
  padding: "3rem",
  display: "flex",
  flexDirection: "column",
  maxWidth: "90vw",
  maxHeight: "90vh",
}));

const ConfirmDeleteModal = ({ text, action, onSucceed }) => {
  const { showModal, toggleModal, modalPayload } = useModal();
  // const [accessToken, setAccessToken] = useLocalStorage("access", "");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async ({ password }) => {
    try {
      await action(modalPayload.id, password);
      toggleModal("modal3");
      onSucceed();
    } catch (err) {
      if (err?.response?.data?.message)
        setErrorMessage(err.response.data.message);
      else {
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={showModal.modal3}
      onClose={() => toggleModal("modal3")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModalItemsContainer>
        <Typography
          align="center"
          variant="h2"
          sx={{
            fontWeight: 700,
            fontSize: 24,
            color: "error.main",
          }}
        >
          {text}
        </Typography>
        <Typography
          align="center"
          variant="p"
          sx={{ my: 1, fontSize: 20, color: "red" }}
        >
          {errorMessage}
        </Typography>
        <IconButton
          onClick={() => toggleModal("modal3")}
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            top: "0",
            right: "0",
            mb: 8,
          }}
        >
          <Close />
        </IconButton>

        <Box sx={{ mt: 1 }}>
          <Formik initialValues={{}} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <FormikPasswordField
                  fullWidth
                  margin="dense"
                  requried
                  id="password"
                  name="password"
                  type="password"
                  label="Password"
                />
                <SubmitButton isSubmitting={isSubmitting} />
              </Form>
            )}
          </Formik>
        </Box>
      </StyledModalItemsContainer>
    </Modal>
  );
};

export default ConfirmDeleteModal;
