import React, { useRef, useState, useMemo, useEffect } from "react";
import { Grid, Box, Input, Button } from "@mui/material";
import { StyledButton } from "../../../pages/auth/Login";
import ReactToPrint from "react-to-print";
import Card, { EmptyCard } from "./Card";
import {
  useUpdateVehicleBadgeMutation,
  useUpdatePersonBadgeMutation,
} from "features/badges/personAndVehicleBadgeApiSlice";
import { useGetEventQuery } from "features/events/eventsApiSlice";
import { baseUrl } from "app/api/apiSlice";
import DefaultSnackbar from "components/DefaultSnackbar";
import { styled } from "@mui/system";

function CardForm({ invitations, event }) {
  const StyledContainer = styled(Box)(({}) => ({
    width: "524px",
    height: "734px",
    padding: 15,
  }));
  console.log(invitations);
  const [open, setOpen] = useState(false);

  const [isBackgroundImageChanged, setIsBackgroundImageChanged] =
    useState(false);
  const [update, { isLoading: isUpdateLoading }] =
    useUpdatePersonBadgeMutation();

  const {
    data: currentEvent,
    isLoading,
    refetch: refetchEvent,
  } = useGetEventQuery(event.id);

  const [bgImg, setBgImg] = useState(null);
  const [currentBgImg, setCurrentBgImg] = useState(null);
  const handleClose = () => {
    setSnackOptions({
      open: false,
    });
  };
  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: "",
  });

  const componentRef = useRef();
  console.log(bgImg);
  useEffect(() => {
    if (currentEvent) {
      const initialBgImg = currentEvent?.person_badge_image?.image_small_path
        ? `${baseUrl}${currentEvent.person_badge_image.image_small_path}`
        : null;
      setBgImg(initialBgImg);
    }
  }, [currentEvent]);

  function changeHandler(e) {
    const selectedImage = e.target.files[0];
    setIsBackgroundImageChanged(true);
    setCurrentBgImg(selectedImage);
    setBgImg(window.URL.createObjectURL(selectedImage));
  }

  async function saveBackgroundImageHandler() {
    if (isBackgroundImageChanged) {
      const formData = new FormData();
      formData.append("event_id", event.id);
      formData.append("image_file", currentBgImg);

      try {
        const res = await update(formData);
        if (res?.error) {
          throw new Error(res.error.data.message);
        }
        console.log(res);
        setSnackOptions({
          open: true,
          text: "Successfully saved background image",
        });
        setIsBackgroundImageChanged(false);
        refetchEvent();
      } catch (err) {
        console.error(err);
      }
    } else {
      setSnackOptions({
        open: true,
        text: "Background image already saved",
      });
    }
  }

  // const getRem = (c) => {
  //   return c < 8 ? 8 - c : c % 8;
  // };

  return (
    <>
      <Input name="image_file" onChange={changeHandler} type="file" />
      <ReactToPrint
        trigger={() => {
          return (
            <Box
              pt={3}
              pr={2}
              pb={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <StyledButton sx={{ marginRight: 2 }}>Print Badges</StyledButton>
              {bgImg && (
                <Button
                  color="success"
                  variant="contained"
                  onClick={saveBackgroundImageHandler}
                  sx={{ padding: "8px 16px" }} // Adjust padding as needed
                >
                  Save Background Image
                </Button>
              )}
            </Box>
          );
        }}
        content={() => componentRef.current}
      />
      <StyledContainer>
        <Grid
          ref={(el) => (componentRef.current = el)}
          container
          spacing={2}
          sx={{
            "@media print": {
              width: "100%",
              margin: 0,
              padding: 0,
              boxSizing: "border-box",
            },
          }}
        >
          {invitations?.map((inv, index) => (
            <Grid item xs={12} key={inv?.id}>
              <Card
                salutation={inv?.attendee?.salutation}
                name={inv?.name}
                sex={inv?.sex}
                image={inv?.image}
                phone={inv?.phone}
                organization={inv?.organization}
                rsvp={inv?.rsvp}
                ticket={inv?.ticket}
                email={inv?.email}
                event={event}
                bgImg={bgImg}
                location={inv?.location}
                is_vip={inv?.is_vip}
              />
            </Grid>
          ))}
        </Grid>
      </StyledContainer>
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </>
  );
}

export default CardForm;
