import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import AttendeeImg from "../../../res/images/attendee.png";
import AttendeeBadgeImg from "../../../res/images/VIPBadgeTemplate.jpg";
import moment from "moment";
import QRCode from "react-qr-code";
import { baseUrl } from "../../../app/api/apiSlice";

const StyledContainer = styled(Box)(({}) => ({
  width: "460px",
  height: "634px",
  padding: 15,
}));

export const StyledBalckTypo = styled(Typography)(({ theme }) => ({
  textShadow:
    "-1px 1px 0 #fff, 1px 1px 0 #fff,1px -1px 0 #fff, -1px -1px 0 #fff",
}));

export const EmptyCard = (props) => {
  return <StyledContainer></StyledContainer>;
};

function AttendeeCard({
  salutation,
  name,
  image,
  sex,
  organization,
  event,
  bgImg,
  ticket,
  is_vip,
}) {
  console.log(salutation);
  return (
    <StyledContainer
      sx={{
        backgroundImage: !bgImg ? `url(${AttendeeBadgeImg})` : `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* <Box ml={6} mt={6}>
       
        {event?.start_date === event?.end_date ? (
          <StyledBalckTypo fontSize={14}>
            {moment(event?.start_date).format("MMM DD")}
          </StyledBalckTypo>
        ) : (
          <StyledBalckTypo>
            {" "}
            {moment(event?.start_date).format("MMM DD")} -{" "}
            {moment(event?.end_date).format("MMM DD")}
          </StyledBalckTypo>
        )}
      </Box> */}
      <Box pt={20} pl={6}>
        {[name, organization?.name].map((item) => (
          <StyledBalckTypo pb={1} fontWeight={500} variant="h5" key={item}>
            {item === name ? (
              <>
                {salutation} {item}
              </>
            ) : (
              item
            )}
          </StyledBalckTypo>
        ))}
      </Box>

      {/* <StyledBalckTypo pl={6} variant="h5" fontWeight={500}>
        {event?.name}
      </StyledBalckTypo> */}
      <Typography
        variant="h6"
        fontWeight={700}
        fontSize={34}
        color="#fff"
        ml={8}
      >
        {is_vip ? "VIP" : "REGULAR"}
      </Typography>
      <Box sx={{ display: "flex" }}>
        {ticket != null && (
          <QRCode
            size={256}
            style={{
              height: "auto",
              maxWidth: "90%",
              marginLeft: "43px",
              width: "200px",
              backgroundColor: "#fff",
              padding: 10,
              border: "1px solid #000",
            }}
            value={ticket}
            viewBox={`0 0 256 256`}
          />
        )}
      </Box>
      {/* <StyledBalckTypo textAlign="center" mb={"15px"}>
        {event?.location?.name}
      </StyledBalckTypo> */}
    </StyledContainer>
  );
}

export default AttendeeCard;
