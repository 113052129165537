import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { CalendlyURL } from "constants/api";


const DvrSetting = ({ dvr_setting }) => {
  const dvrKeys = [
    "max_no_events",
    "max_no_contacts",
    "max_no_email_marketing_campaigns",
    "max_no_marketing_campaign_templates",
    "max_no_invitation",
    "max_no_system_users",
    "max_no_in_app_ads",
    "max_no_push_notifications",
  ];

  const labels = {
    max_no_events: "Max Events",
    max_no_contacts: "Max Contacts",
    max_no_email_marketing_campaigns: "Max Email Marketing Campaigns",
    max_no_marketing_campaign_templates: "Max Marketing Campaign Templates",
    max_no_invitation: "Max Invitation",
    max_no_system_users: "Max System Users",
    max_no_in_app_ads: "Max Number of In App Ads",
    max_no_push_notifications: "Max Number of Push Notifications",
  };

  return ( 
    <Grid container spacing={2}>
      {dvrKeys.map((key) => (
        <Grid key={key} item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                fontSize={"10px"}
                variant="body"
                sx={{ color: "#333333" }}
              >
                {labels[key]}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body" color="#333333">
                {dvr_setting[key] || 0}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              fontSize={"10px"}
              variant="body"
              sx={{ color: "#333333" }}
            >
              {"Venue Rental Fee"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body" color="#333333">
              {"2%"}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              fontSize={"10px"}
              variant="body"
              sx={{ color: "#333333" }}
            >
              {"Ticket Fee"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body" color="#333333">
              {"10.5%"}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const Price = ({ price, currency, duration, isCustom, plan, option }) => {
  const currencySymbol = currency === "USD" ? "USD" : "ETB";
  return (
    <Typography
      variant="h4"
      textAlign={"center"}
      fontWeight={600}
      sx={{
        color: plan.is_recommended ? "primary.main" : "#333333",
      }}
    >
      {isCustom ? (
        "Custom"
      ) : (
        <>
          <span style={{ fontSize: "1.2rem" }}>{currencySymbol}</span> {price}
          <span style={{ fontSize: "1.4rem", color: "#333333" }}>
            / {option?.duration}{" "}
            {option?.duration_type === "MONTH"
              ? "Months"
              : option?.duration_type === "YEAR"
              ? "Year"
              : "Days"}
          </span>
        </>
      )}
    </Typography>
  );
};

const PaymentPlanVariant = ({
  id,
  name,
  title,
  features,
  plan,
  isCustom,
  setIsSelected,
  setSelectedID,
  options,
  isSelected,
  selectedID,
  selectedCurrency
}) => {
  const handleSelection = (id) => {
    if (isCustom) {
      window.open(CalendlyURL, "_self");
    } else {
      setSelectedID(id);
      setIsSelected((prev) => !prev);
    }
  };

  // Get the currency details for the selected currency only
  const currencyDetails = options?.prices?.find(item => item.currency === selectedCurrency);

  // If no matching currency details found, or if it's a custom plan, skip rendering
  if (!isCustom && !currencyDetails) return null;

  return (
    <Box sx={{ width: "18rem" }}>
      <Card
        sx={{
          py: 0,
          height: "fit-content",
          backgroundColor: selectedID === id ? "#D8D8D8" : "#F2F2F2",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: `${selectedID === id ? "solid 2px #DEA449" : ""}`,
        }}
      >
        <CardHeader
          title={name}
          titleTypographyProps={{
            fontSize: "20px",
            fontWeight: 700,
            textTransform: "capitalize",
            maxWidth: "90%",
            color: plan.is_recommended ? "#DEA449" : "#333333",
          }}
        />
        <CardContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <Price
            price={isCustom ? "Custom" : currencyDetails.price}
            currency={currencyDetails?.currency || ''}
            duration={`${options?.duration} ${options?.duration_type}`}
            isCustom={isCustom}
            plan={plan}
            option={options}
          />
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <DvrSetting dvr_setting={options?.dvr_setting} />
          </Grid>
          <Button
            variant="contained"
            onClick={() => handleSelection(options?.id)}
          >
            {isCustom ? "Contact Us" : "Select"}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PaymentPlanVariant;
