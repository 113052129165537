import { 
  // alpha, 
  styled } from "@mui/system";
import { 
  // Box, 
  Dialog, IconButton, Paper, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { useState } from "react";
import {
  FormikPasswordField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";

export const StyledModalItemsContainer = styled(Paper)(({ theme }) => ({
  position: "relative",
  background: "#fff",
  padding: "2rem",
  display: "flex",
  flexDirection: "column",
}));

const ConfirmDeleteDialog = ({ open, onClose, text, action }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async ({ password }) => {
    try {
      await action(password);
      // onSucceed();
      onClose();
    } catch (err) {
      if (err?.response?.data?.message)
        setErrorMessage(err.response.data.message);
      else {
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <Dialog
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModalItemsContainer>
        <Typography
          align="center"
          variant="h2"
          sx={{
            fontWeight: 700,
            mb: 3,
            fontSize: 24,
            color: "secondary.light",
          }}
        >
          {text}
        </Typography>
        <Typography
          align="center"
          variant="p"
          sx={{ mb: 3, fontSize: 20, color: "red" }}
        >
          {errorMessage}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            top: "0",
            right: "0",
            mb: 8,
          }}
        >
          <Close />
        </IconButton>

        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <FormikPasswordField
                required
                fullWidth
                margin="dense"
                id="password"
                name="password"
                label="Password"
              />

              <SubmitButton text="Delete" isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      </StyledModalItemsContainer>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
