import { Close } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, IconButton, Modal, Typography } from "@mui/material";
import { ModalItemsContainer } from "components/CustomModal/Styles";
import {
  FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";
import { useModal } from "contexts/modalContext";
// import { useAddServiceMutation } from "features/serviceProvider/serviceproviderApiSlice";
import { Form, Formik } from "formik";
import { useState } from "react";

const CreateServicesModal = (props) => {
  const { showModal, toggleModal, modalPayload } = useModal();
  const [errorMessage, setErrorMessage] = useState("");
  // const [createService, response] = useAddServiceMutation();

  const editMode = modalPayload.action === "edit";
  const viewMode = modalPayload.action === "view";

  const promptText = () => {
    if (editMode) {
      return "Edit Service";
    }
    if (viewMode) {
      return "Event Request Details";
    } else return "Create Service";
  };

  const getInitialValues = () => {
    return {
      name: "",
      description: "",
      price: "",
      accessories: [
        {
          name: "",
          type: "bool",
          value: "true",
          price: "",
        },
      ],
    };
  };

  const [accessoriesForm, setAccessoriesForm] = useState(0);

  const handleAddAccessories = () => {
    setAccessoriesForm((prev) => prev + 1);
  };

  const handleAdd = async (values) => {};
  const handleEdit = async (values) => {};

  const handleSubmit = async (values) => {
    if (editMode) {
      await handleEdit(values);
    } else if (viewMode) {
    } else {
      await handleAdd(values);
    }
  };

  const AccessoriesForm = () => {
    const accessories = [];
    for (let i = 0; i < accessoriesForm; i++) {
      accessories.push(
        <div key={i}>
          <FormikTextField
            fullWidth
            label={`Accessory Name ${i + 1}`}
            name={`accessories[${i}].name`}
          />
          <FormikTextField
            fullWidth
            label={`Accessory Price ${i + 1}`}
            name={`accessories[${i}].price`}
          />
        </div>
      );
    }

    return <>{accessories}</>;
  };

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={showModal.modal1}
      onClose={() => toggleModal("modal1")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalItemsContainer>
        <Typography
          align="center"
          variant="h2"
          sx={{ fontWeight: 700, mb: 3, fontSize: 24, color: "primary.main" }}
        >
          {promptText()}
        </Typography>

        <Typography
          align="center"
          variant="p"
          sx={{ mb: 3, fontSize: 20, color: "red" }}
        >
          {errorMessage}
        </Typography>
        <IconButton
          onClick={() => toggleModal("modal1")}
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            top: "0",
            right: "0",
            mb: 8,
          }}
        >
          <Close />
        </IconButton>
        <Formik initialValues={getInitialValues()} onSubmit={handleSubmit}>
          {({ isSubmitting, values }) => (
            <Form>
              <FormikTextField fullWidth label="Name" name="name" />
              <FormikTextField
                fullWidth
                label="Description"
                name="description"
              />
              <FormikTextField fullWidth label="Price" name="price" />
              <Grid
                display="flex"
                flexDirection="row"
                gap={1}
                sx={{ float: "right" }}
              >
                <Typography alignSelf="center" color="#333333">
                  Click here to add accessories
                </Typography>
                <IconButton onClick={handleAddAccessories}>
                  <AddCircleIcon sx={{ color: "primary.main" }} />
                </IconButton>
              </Grid>
              <AccessoriesForm />
              <SubmitButton text="Submit" />
            </Form>
          )}
        </Formik>
      </ModalItemsContainer>
    </Modal>
  );
};

export default CreateServicesModal;
